import { createElementProps } from 'react-syntax-highlighter';
import { Code, CodeProps } from '@chakra-ui/react';

import CopyButton from 'components/CopyButton';

type CodeBlockProps = CodeProps & createElementProps;

const CodeBlock = ({ style = {}, children, node, ...props }: CodeBlockProps) => {
  const code = node.children && node.children.length > 0 ? node.children[0].value : '';

  if (typeof code !== 'string') return <></>;

  return (
    <CopyButton code={code}>
      <Code style={{ width: '100%', ...style, padding: '1em' }} {...props}>
        {children}
      </Code>
    </CopyButton>
  );
};

export default CodeBlock;
