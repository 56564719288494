import { CSSProperties } from 'react';

export const editorColors = {
  magenta: '#A626A4',
  grey: '#A0A1A7',
  green: '#50A14F',
  blue: '#0184BC',
  white: '#FAFAFA',
  dark: '#383A42',
};

export type SyntaxTheme = {
  [key: string]: CSSProperties;
};

export const syntaxLight: SyntaxTheme = {
  'hljs': {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: 'translucent',
    color: editorColors.dark,
  },
  'hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-selector-tag': {
    color: editorColors.magenta,
  },
  'hljs-literal': {
    color: editorColors.magenta,
  },
  'hljs-section': {
    color: editorColors.magenta,
  },
  'hljs-link': {
    color: editorColors.magenta,
  },
  'hljs-function .hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-subst': {
    color: editorColors.dark,
  },
  'hljs-string': {
    color: editorColors.green,
  },
  'hljs-title': {
    color: editorColors.dark,
  },
  'hljs-name': {
    color: editorColors.green,
  },
  'hljs-type': {
    color: editorColors.green,
  },
  'hljs-attribute': {
    color: editorColors.green,
  },
  'hljs-symbol': {
    color: editorColors.green,
  },
  'hljs-bullet': {
    color: editorColors.green,
  },
  'hljs-addition': {
    color: editorColors.green,
  },
  'hljs-variable': {
    color: editorColors.green,
  },
  'hljs-template-tag': {
    color: editorColors.green,
  },
  'hljs-template-variable': {
    color: editorColors.green,
  },
  'hljs-comment': {
    color: editorColors.grey,
  },
  'hljs-quote': {
    color: editorColors.green,
  },
  'hljs-deletion': {
    color: editorColors.green,
  },
  'hljs-meta': {
    color: editorColors.green,
  },
  'hljs-doctag': {
    fontWeight: 'bold',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
};
