import { useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import useAppView from './useAppView';

function useSidebar() {
  const router = useRouter();

  const resetQuery = (query: ParsedUrlQuery) => {
    delete query.table;
    delete query.query;
    delete query.chart;
    delete query.showSidebar;
    delete query.showQueries; // for requests sidebar
    delete query.edit;
    return query;
  };

  const sidebarWorkUID = useMemo(
    () => router.query.workUID && (router.query.workUID as string),
    [router.query.workUID],
  );

  const { appView, chatUID } = useAppView();

  const setShowWorkSidebar = useCallback(
    async (workUID?: string, newChatUID?: string) => {
      if (!workUID) {
        delete router.query.workUID;
      } else {
        router.query.workUID = workUID;
      }

      if (router.query.view !== 'requests') {
        router.query.view = 'requests';
        router.query.viewItemId = newChatUID;
      }

      delete router.query.messageUID;
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const sidebarCommentsUID = useMemo(
    () => router.query.messageUID && (router.query.messageUID as string),
    [router.query.messageUID],
  );
  const setShowCommentsSidebar = useCallback(
    async (messageUID?: string) => {
      if (!messageUID) {
        delete router.query.messageUID;
      } else {
        router.query.messageUID = messageUID;
      }
      delete router.query.workUID;
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const sidebarQueryUID = useMemo(
    () => router.query.query && (router.query.query as string),
    [router.query.query],
  );

  const showQuerySidebar = useCallback(
    async (queryUID: string) => {
      router.query = resetQuery(router.query);
      router.query.query = queryUID;
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const sidebarChartUID = useMemo(
    () => router.query.chart && (router.query.chart as string),
    [router.query.chart],
  );

  const showChartSidebar = useCallback(
    async (queryUID: string, chartUID: string) => {
      router.query = resetQuery(router.query);
      router.query.query = queryUID;
      router.query.chart = chartUID;
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const sidebarTableUID = useMemo(
    () => router.query.table && (router.query.table as string),
    [router.query.table],
  );

  const showTableSidebar = useCallback(
    async (tableUID: string) => {
      router.query = resetQuery(router.query);
      router.query.table = tableUID;
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const sidebarMetricUID = useMemo(
    () => router.query.metric && (router.query.metric as string),
    [router.query.metric],
  );

  const showMetricSidebar = useCallback(
    async (metricUID?: string) => {
      router.query = resetQuery(router.query);
      if (!metricUID) {
        delete router.query.metric;
      } else {
        router.query.metric = metricUID;
      }
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const editMode = useMemo(() => (router.query.edit as string) === 'true', [router.query.edit]);

  const setEditMode = useCallback(
    async (edit: boolean) => {
      if (edit) {
        router.query.edit = 'true';
      } else {
        delete router.query.edit;
      }
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const closeSidebar = useCallback(async () => {
    router.query = resetQuery(router.query);
    await router.replace({ query: router.query });
  }, [router.query, router.replace]);

  const setShowSidebar = useCallback(
    async (showSidebar: boolean) => {
      if (!showSidebar) {
        router.query.showSidebar = 'false';
      } else {
        delete router.query.showSidebar;
      }
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const showSidebar = useMemo(
    () => (router.query.showSidebar as string) !== 'false',
    [router.query.showSidebar],
  );

  const setShowQueries = useCallback(
    async (showQueries: boolean) => {
      router.query = resetQuery(router.query);
      if (showQueries) {
        router.query.showQueries = 'true';
      }
      await router.replace({ query: router.query });
    },
    [router.query, router.replace],
  );

  const showQueries = useMemo(() => {
    return (router.query.showQueries as string) == 'true' && appView === 'requests' && chatUID;
  }, [appView, chatUID, router.query.showQueries]);

  useHotkeys('Escape', () => setShowSidebar(false), { enabled: appView !== 'metrics' });

  return {
    sidebarWorkUID,
    setShowWorkSidebar,
    //
    sidebarQueryUID,
    showQuerySidebar,
    //
    sidebarTableUID,
    showTableSidebar,
    //
    sidebarMetricUID,
    showMetricSidebar,
    //
    sidebarChartUID,
    showChartSidebar,
    //
    showQueries,
    setShowQueries,
    //
    editMode,
    setEditMode,
    //
    showSidebar,
    setShowSidebar,
    //
    closeSidebar,
    //
    sidebarCommentsUID,
    setShowCommentsSidebar,
  };
}

export default useSidebar;
