import { visit } from 'unist-util-visit';

// https://github.com/remarkjs/react-markdown/issues/585
export default function myRemarkPlugin() {
  return (tree: any) => {
    visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], (node) => {
      node.data = {
        hName: node.name,
        hProperties: node.attributes,
        ...node.data,
      };
      return node;
    });
  };
}
