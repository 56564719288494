import { ChangeEvent, useEffect, useRef } from 'react';
import { Button } from '@chakra-ui/react';

type FileInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  openOnMount?: boolean;
  label?: string;
  onChange: (file: File | null) => void;
};

const FileInput = ({
  openOnMount = false,
  onChange,
  label = 'Choose File',
  ...rest
}: FileInputProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const selectedFile = fileList[0];
      onChange(selectedFile);
    } else {
      onChange(null);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    // This will fire twice during local development
    openOnMount && handleClick();
  }, [openOnMount]);

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        {...rest}
      />
      <Button variant="outline" onClick={handleClick} size="sm">
        {label}
      </Button>
    </>
  );
};

export default FileInput;
