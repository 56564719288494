import React, { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  Box,
  ChakraProps,
  Checkbox,
  Code,
  Image,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import directive from 'remark-directive';
import remarkGfm from 'remark-gfm';
import { colors } from 'styles/colors';

import { syntaxDark } from 'utils/darktheme';
import { syntaxLight, SyntaxTheme } from 'utils/lighttheme';

import ChartReference from './ChartReference';
import CodeBlock from './CodeBlock';
import nodePlugin from './nodePlugin';
import SqlReference from './SqlReference';

type MarkdownProps = ChakraProps & {
  children?: string;
  onChange?: (value: string) => void;
  placeHolderText?: string;
};

const uncheckedRegex = /^(.*)(\[  ?\])(.*)$/m;
const checkedRegex = /^(.*)(\[x\])(.*)$/m;

const Markdown = forwardRef<HTMLDivElement, MarkdownProps>(
  ({ fontSize = '14px', children: childrenOriginal, onChange, placeHolderText, ...rest }, ref) => {
    const theme = useColorModeValue<SyntaxTheme>(syntaxLight, syntaxDark);

    const [children, setChildren] = useState(childrenOriginal);
    useEffect(() => {
      setChildren(childrenOriginal);
    }, [childrenOriginal, setChildren]);

    const toggleCheckbox = useCallback(
      (lineNumber: number, isChecked: boolean) => {
        if (!children) return;
        const lines = children.split('\n');
        if (isChecked) {
          lines[lineNumber - 1] = lines[lineNumber - 1].replace(uncheckedRegex, '$1[x]$3');
        } else {
          lines[lineNumber - 1] = lines[lineNumber - 1].replace(checkedRegex, '$1[ ]$3');
        }
        const newChildren = lines.join('\n');
        setChildren(newChildren);
        onChange?.(newChildren);
      },
      [children, onChange, setChildren],
    );

    if (!children) return <></>;

    return (
      <Box ref={ref} {...rest} fontSize={fontSize}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm, directive, nodePlugin]}
          linkTarget="_blank"
          className="markdown"
          rawSourcePos={true}
          components={{
            // @ts-ignore
            Chart({ children, ...props }) {
              if (!props.id) return <></>;
              return <ChartReference {...{ children, props }} />;
            },
            // @ts-ignore
            Sql({ children, ...props }) {
              if (!props.id) return <></>;
              return <SqlReference {...{ children, props }} />;
            },
            code({ inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  // @ts-ignore Typescript bug with @types/react-syntax-highlighter
                  style={{ ...theme, fontSize }}
                  PreTag={CodeBlock}
                  language={match[1]}
                  {...props}
                >
                  {String(children).replace(/\n$/, '').trim()}
                </SyntaxHighlighter>
              ) : (
                <Code
                  className={className}
                  style={{ color: colors.light.text1 }}
                  {...props}
                  fontSize={fontSize}
                >
                  {children}
                </Code>
              );
            },
            a({ children, ...props }) {
              return (
                <Link {...props} overflowWrap="anywhere">
                  {children}
                </Link>
              );
            },
            table({ children }) {
              return <Table size="xs">{children}</Table>;
            },
            thead({ children }) {
              return <Thead>{children}</Thead>;
            },
            tbody({ children }) {
              return <Tbody>{children}</Tbody>;
            },
            tr({ children }) {
              return <Tr>{children}</Tr>;
            },
            th({ children }) {
              return <Th>{children}</Th>;
            },
            td({ children }) {
              return <Td>{children}</Td>;
            },
            img({ children, ...props }) {
              return (
                <Image alt="image" {...props}>
                  {children}
                </Image>
              );
            },
            li({ checked, sourcePosition, ...props }) {
              if (checked == null) {
                return <li {...props} />;
              }
              return (
                // https://github.com/remarkjs/react-markdown/issues/607#issuecomment-864361480
                <li {...props}>
                  {React.Children.map(props.children, (child) =>
                    child &&
                    typeof child === 'object' &&
                    'type' in child &&
                    child.type === 'input' &&
                    child.props.type === 'checkbox' ? (
                      <Checkbox
                        isChecked={child.props.checked}
                        isReadOnly={!onChange}
                        onChange={() => {
                          toggleCheckbox?.(sourcePosition!.start.line, !child.props.checked);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        className="nodrag"
                      />
                    ) : (
                      child
                    ),
                  )}
                </li>
              );
            },
          }}
        >
          {children || placeHolderText || ''}
        </ReactMarkdown>
      </Box>
    );
  },
);

Markdown.displayName = 'Markdown';

export default memo(Markdown);
