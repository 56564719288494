import { useQuery as useReactQuery, UseQueryOptions } from '@tanstack/react-query';

import { Query } from 'types/api';
import { getQueryURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { QueryMetadataQueryKey } from 'utils/queryKeys';

function useQuery(queryUID?: string, options?: UseQueryOptions<Query>) {
  const url = getQueryURL(queryUID!);

  return useReactQuery<Query>(
    QueryMetadataQueryKey(queryUID!),
    async () => {
      const res = await Axios.get<Query>(url!);
      return res.data;
    },
    {
      ...options,
      enabled: !!queryUID,
    },
  );
}

export default useQuery;
