import React, { ReactNode, useMemo } from 'react';
import { Center, Link, Spinner } from '@chakra-ui/react';

import useQuery from 'hooks/api/queries/useQuery';
import useSidebar from 'hooks/useSidebar';

type ChartReferenceProps = {
  props: any;
  children: ReactNode;
};

const ChartReference = ({ props, children }: ChartReferenceProps) => {
  const { showQuerySidebar } = useSidebar();

  const { data: query, isLoading } = useQuery(props.id);

  const chart = useMemo(
    () => query?.charts?.find((chart) => chart.uid === props.id),
    [query, props.id],
  );

  if (isLoading) {
    return (
      <Center w="full" h="full">
        <Spinner speed={'1.5s'} />
      </Center>
    );
  }

  return (
    <span {...props.props}>
      {!chart?.name ? (
        <span style={{ fontWeight: 'bold', color: 'red' }}>Invalid node ID [{props.id}]</span>
      ) : (
        <Link
          {...props}
          fontWeight="bold"
          overflowWrap="anywhere"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            query?.uid && showQuerySidebar(query.uid);
          }}
        >
          {!!children ? <>{children}</> : <>{chart?.name}</>}
        </Link>
      )}
    </span>
  );
};

export default ChartReference;
