import { useState } from 'react';
import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { P2 } from 'components/Typography';

import FileInput from './FileInput';

export type UploadedFile = {
  name: string;
  file: File;
  shared?: boolean;
};

type Props = {
  onClose: (value?: UploadedFile) => void;
  showShareChat?: boolean;
};

export const AddCsvModal = ({ onClose, showShareChat }: Props) => {
  const [isShared, setIsShared] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<'type' | 'size' | null>(null);

  const handleFileChange = (file: File | null) => {
    if (!file) {
      setSelectedFile(file);
      setFileError(null);
    } else if (file?.type !== 'text/csv') {
      setFileError('type');
      setSelectedFile(null);
    } else if (file?.size && file?.size >= 31457280) {
      // 30 mega bytes = 31,457,280 Bytes
      setFileError('size');
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
      setFileError(null);
      setFileName(file.name.replace('.csv', '').replace(/[^a-zA-Z0-9_-]/g, ''));
    }
  };

  const handleSave = async () => {
    if (fileName && selectedFile) {
      onClose({ name: fileName, file: selectedFile, shared: showShareChat ? isShared : undefined });
    }
  };

  return (
    <Modal isOpen={true} onClose={() => onClose(undefined)}>
      <ModalOverlay />
      <ModalContent bg={'white'}>
        <ModalHeader>Choose a CSV to import</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <P2 color="text3" mb={4} mt={-2}>
              <li>Max file size for upload is 30MB</li>
              <li>File must be a .csv file</li>
              <li>File must include a header row</li>
            </P2>
            <Flex gap={4} flexDir={'column'}>
              {selectedFile && (
                <Flex gap={4} flexDir={'column'}>
                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                    <P2>
                      <b>Selected file:</b> <li>{selectedFile.name}</li>
                    </P2>
                    <IconButton
                      aria-label={'De-select CSV File'}
                      icon={<DeleteIcon />}
                      colorScheme={'red'}
                      variant={'ghost'}
                      size={'sm'}
                      onClick={() => setSelectedFile(null)}
                    />
                  </Flex>
                  <Input
                    placeholder="Set the table name"
                    value={fileName}
                    onChange={(event) => setFileName(event.target.value)}
                  />
                  <Divider />
                </Flex>
              )}
              {!selectedFile && <FileInput onChange={handleFileChange} accept=".csv" />}
            </Flex>
            {fileError === 'type' && (
              <P2 color="red" mt={1}>
                Selected file is not a .csv
              </P2>
            )}
            {fileError === 'size' && (
              <P2 color="red" mt={1}>
                Selected csv exceeds maximum file size
              </P2>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          {selectedFile && (
            <Button mr={3} size="sm" onClick={handleSave} isDisabled={!fileName || !selectedFile}>
              Import CSV
            </Button>
          )}
          <Button variant="outline" size="sm" onClick={() => onClose(undefined)}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
