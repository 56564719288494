import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { AppView } from 'types';
import { makeGraphURL } from 'utils';

import useGraph, { useGraphUID } from './api/graphs/useGraph';

function useAppView() {
  const { data: graph } = useGraph({ select: (g) => ({ uid: g.uid, slug: g.slug }) });
  const router = useRouter();

  const appView = useMemo(
    () => router.query.view && (router.query.view as string),
    [router.query.view],
  );
  const itemID = useMemo(
    () => router.query.viewItemId && (router.query.viewItemId as string),
    [router.query.viewItemId],
  );

  const graphUID = useGraphUID();

  const setAppView = useCallback(
    async (
      newAppView: AppView,
      newItemID: string | undefined = undefined,
      queryParams: Record<string, string> | undefined = {},
    ) => {
      let newURL = makeGraphURL({
        uid: graphUID,
        view: newAppView,
        slug: graph?.slug,
        itemID: newItemID,
      });
      const url = new URL(window.location.href);
      url.pathname = newURL;

      if (queryParams !== undefined) {
        const searchParams = new URLSearchParams(queryParams);
        url.search = '?' + searchParams.toString();
      }
      await router.push(url.href);
    },
    [graph?.slug, graphUID, router],
  );

  const setChatUID = useCallback(
    async (chatUID?: string) => await setAppView('requests', chatUID),
    [setAppView],
  );

  const setQueryUID = useCallback(
    async (queryUID?: string) => await setAppView('queries', queryUID),
    [setAppView],
  );

  return {
    appView,
    itemID,
    setAppView,
    chatUID: itemID,
    setChatUID,
    queryUID: itemID,
    setQueryUID: setQueryUID,
  };
}

export default useAppView;
