import { colors } from 'styles/colors';

import { SyntaxTheme } from './lighttheme';

const editorColors = {
  magenta: '#C678DD',
  grey: '#5C6370',
  green: '#98C379',
  blue: '#56B6C2',
  dark: '#333842',
  lightGrey: '#ABB2BF',
};

export const syntaxDark: SyntaxTheme = {
  'hljs': {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: colors.dark.bg1,
    color: colors.gray[300],
  },
  'hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-selector-tag': {
    color: editorColors.magenta,
  },
  'hljs-literal': {
    color: editorColors.magenta,
  },
  'hljs-section': {
    color: editorColors.magenta,
  },
  'hljs-link': {
    color: editorColors.magenta,
  },
  'hljs-function .hljs-keyword': {
    color: editorColors.magenta,
  },
  'hljs-subst': {
    color: editorColors.lightGrey,
  },
  'hljs-string': {
    color: editorColors.green,
  },
  'hljs-title': {
    color: editorColors.lightGrey,
  },
  'hljs-name': {
    color: editorColors.green,
  },
  'hljs-type': {
    color: editorColors.green,
  },
  'hljs-attribute': {
    color: editorColors.green,
  },
  'hljs-symbol': {
    color: editorColors.green,
  },
  'hljs-bullet': {
    color: editorColors.green,
  },
  'hljs-addition': {
    color: editorColors.green,
  },
  'hljs-variable': {
    color: editorColors.green,
  },
  'hljs-template-tag': {
    color: editorColors.green,
  },
  'hljs-template-variable': {
    color: editorColors.green,
  },
  'hljs-comment': {
    color: editorColors.grey,
  },
  'hljs-quote': {
    color: editorColors.green,
  },
  'hljs-deletion': {
    color: editorColors.green,
  },
  'hljs-meta': {
    color: editorColors.green,
  },
  'hljs-doctag': {
    fontWeight: 'bold',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
};
